import React from "react";
import pageImage from "images/HomepageLogo.png";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import { FaChevronRight } from "react-icons/fa";
import { FullBackground, Icon, Layout, PageContainer, SEO } from "components";
import { Link as GatsbyLink } from "gatsby";
import { device, routes } from "utils";

const HowItWorksPage = ({ location }) => {
  const title = "How Cloud Laboratories Work";
  const description =
    "Learn how users design, execute, analyze and visualize experiments remotely";

  const size1 = 6;
  const size2 = 3;

  return (
    <FullBackground>
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <MobileBanner to={routes("HIWCommand")}>See How It Works</MobileBanner>
        <PageContainer style={{ borderTop: "1px solid #53575b" }}>
          <Heading>
            <MainTitle>The ECL is at your command</MainTitle>
            <SubHeader>
              The ECL Command Center provides a single, comprehensive workspace
              for your entire scientific workflow.
            </SubHeader>
            <StartButton to={routes("HIWCommand")}>
              <ActionBtn>
                See how it works <ArrowIcon />
              </ActionBtn>
            </StartButton>
          </Heading>
          <StyledRow>
            <StyledCol sm={size1} md={size2}>
              <StyledLink to={routes("HIWCommand")}>
                <StyledIcon name="CommandIcon" />
              </StyledLink>
              <StepTitle>1. Command</StepTitle>
              <StepDesc>
                Ship your samples to an ECL facility and design your experiments
                in the ECL Command Center application.
              </StepDesc>
            </StyledCol>
            <StyledCol sm={size1} md={size2}>
              <StyledLink to={routes("HIWRun")}>
                <StyledIcon name="RunIcon" />
              </StyledLink>
              <StepTitle>2. Run</StepTitle>
              <StepDesc>
                Emerald remotely conducts your experiments in a highly automated
                ECL facility exactly to your specifications.
              </StepDesc>
            </StyledCol>
            <StyledCol sm={size1} md={size2}>
              <StyledLink to={routes("HIWExplore")}>
                <StyledIcon name="ExploreIcon" />
              </StyledLink>
              <StepTitle>3. Explore</StepTitle>
              <StepDesc>
                ECL Constellation organizes your data into a powerful knowledge
                graph, growing automatically over time as you conduct more
                experiments.
              </StepDesc>
            </StyledCol>
            <StyledCol sm={size1} md={size2}>
              <StyledLink to={routes("HIWAnalyze")}>
                <StyledIcon name="AnalyzeIcon" />
              </StyledLink>
              <StepTitle>4. Analyze</StepTitle>
              <StepDesc>
                ECL Command Center provides an extensive suite of tools to plot,
                analyze, and visualize your results.
              </StepDesc>
            </StyledCol>
          </StyledRow>
        </PageContainer>{" "}
      </Layout>
    </FullBackground>
  );
};

export default HowItWorksPage;

// Styles

const MobileBanner = styled(GatsbyLink)`
  background-color: ${({ theme }) => theme.greenLink};
  color: ${({ theme }) => theme.white};
  display: block;
  font-size: 1.4rem;
  left: 0;
  line-height: 2rem;
  padding: 1rem;
  position: fixed;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.25s ease;
  top: 5rem;
  width: 100%;
  z-index: 2;

  @media ${device.sm} {
    display: none;
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.white};
    text-decoration: none;
    opacity: 0.8;
  }
`;

const StyledRow = styled(Row)`
  justify-content: center;
  margin-bottom: 5rem;
  margin-top: 0;
  display: flex;
  flex-direction: row;

  @media ${device.sm} {
    margin-bottom: 6rem;
    margin-top: 2.6rem;
  }
`;

// TODO: The header parts matches the other two main pages.
// Move it to somewhere common
// Heading + MainTitle + SubHeader

const Heading = styled.div`
  letter-spacing: 0.07rem;
  margin-bottom: 0;
  margin-top: 10.3rem;
  text-align: center;

  @media ${device.sm} {
    margin-bottom: 6rem;
    margin-top: 6.5rem;
  }
`;

const MainTitle = styled.h1`
  color: ${({ theme }) => theme.white};
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 3.6rem;
  margin-bottom: 1rem;
`;

const SubHeader = styled.p`
  color: #b1bac2;
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 2.5rem;
  margin-top: 0;

  @media ${device.sm} {
    margin-top: 1.5rem;
  }
`;

const StartButton = styled(GatsbyLink)`
  display: none;
  transition: all 0.25s ease;

  @media ${device.sm} {
    display: inline-block;
  }

  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }
`;

const ActionBtn = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.greenLink};
  border-radius: 0;
  border: none;
  color: ${({ theme }) => theme.white};
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
  height: 3.2rem;
  justify-content: center;
  margin: auto;
  outline: none;
  padding: 0 1.2rem;
  position: relative;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }
`;

const ArrowIcon = styled(FaChevronRight)`
  height: 13px;
  left: 0.3rem;
  position: relative;
  top: -0.1rem;
`;

const StyledCol = styled(Col)`
  margin-bottom: 3rem;
  text-align: center;

  @media ${device.xs} {
  }
`;

const StyledLink = styled(GatsbyLink)`
  cursor: pointer;
  display: inline-block;
  margin-bottom: 2rem;
  transition: all 0.5s ease;

  @media ${device.xs} {
    margin-bottom: 2.5rem;

    &:hover {
      transform: scale(1.15);
    }
  }
`;

const StepTitle = styled.h2`
  color: ${({ theme }) => theme.white};
  font-weight: 300;
  font-size: 2rem;
  margin-bottom: 0.5rem;

  @media ${device.xs} {
    margin-bottom: 1rem;
  }
`;

const StepDesc = styled.p`
  color: #b1bac2;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.8rem;
  margin-bottom: 3rem;
  margin: auto;
  max-width: 400px;

  @media ${device.sm} {
    margin-bottom: 1rem;
    max-width: 215px;
  }ß
`;

const StyledIcon = styled(Icon)`
  height: 200px;
  width: 200px;
  margin-bottom: -2rem;
  margin-top: -0.5rem;

  @media ${device.xs} {
    height: 150px;
    width: 150px;
    margin-bottom: 0;
    margin-top: 0;
  }
`;
